<template>
  <template v-for="item in items" :key="item">
    <div style="background:#fff;padding:15px;margin:7px 0 0 0;">
      <van-row>
        <van-col span="5">
          <van-image
            width="4rem"
            height="4rem"
            :src="item.logo + '?imageView2/1/w/400/h/400'"
          />
        </van-col>
        <van-col span="13">
          <div>
            <p>{{ item.name }}</p>
            <p style="margin:5px 0 0 0;color:#666;">
              <small>{{ item.address }}</small>
            </p>
          </div>
        </van-col>
        <van-col span="6">
          <p>
            <small>
              距你
              <template v-if="item.distance >= 1000">
                {{ parseInt(item.distance / 1000) }} 公里
              </template>
              <template v-else>
                {{ parseInt(item.distance) }} 米
              </template>
            </small>
          </p>
        </van-col>
      </van-row>
      <van-row>
        <van-col span="24">
          <div style="text-align:right;padding:7px 0 0 0;">
            <van-button plain icon="phone-o" type="primary" size="small" @click="onCall(item.tel)">联系电话</van-button>&nbsp;
            <van-button plain icon="location-o" type="primary" size="small" @click="onMap(item)">导航去这里</van-button>
          </div>
        </van-col>
      </van-row>
    </div>
  </template>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'
import wx from 'weixin-js-sdk'
import { gdMap } from '@/util/util'

export default {
  setup () {
    const post = inject('post')
    // const useRoute = inject('useRoute')
    // const useRouter = inject('useRouter')
    const Cookies = inject('Cookies')
    const state = reactive({
      storeId: Cookies.get('storeId'),
      store: {},
      items: [],
      chains: [],
      longitude: '',
      latitude: '',
      from: Cookies.get('from')
    })
    const init = () => {
      Toast.loading({ duration: 0, forbidClick: true })
      gdMap().then(AMap => {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true,
          timeout: 10000
        })
        geolocation.getCurrentPosition((status, result) => {
          if (status === 'complete') {
            post('/outlet.listByDistance', {
              storeId: state.storeId,
              longitude: result.position.lng,
              latitude: result.position.lat
            }).then(res => {
              // state.chains = res.data.filter(v => v.sellerId.toString() !== Cookies.get('seller_id'))
              // state.seller = res.data.filter(v => v.sellerId.toString() === Cookies.get('seller_id'))[0]
              state.items = res.data.items
              Toast.clear()
            })
          } else {
            Toast('定位失败,定位系统失效')
          }
          Toast.clear()
        })
      })
      var u = navigator.userAgent
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      var url = location.href
      if (isiOS) {
        url = window.enterurl
      }
      post('/wechat.getJssdk', {
        url: url
      }).then(res => {
        wx.config({
          debug: false,
          appId: res.data.appId,
          timestamp: res.data.timestamp,
          nonceStr: res.data.nonceStr,
          signature: res.data.signature,
          jsApiList: ['getLocation', 'openLocation']
        })
        wx.ready(() => {
        })
        wx.error(res => {
          alert(JSON.stringify(res))
        })
      })
    }
    const onMap = (value) => {
      wx.openLocation({
        latitude: value.latitude,
        longitude: value.longitude,
        name: value.name,
        address: '',
        scale: 16,
        infoUrl: ''
      })
    }
    const onCall = (tel) => {
      window.location.href = 'tel://' + tel
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onCall,
      onMap
    }
  }
}
</script>
